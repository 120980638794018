import React from 'react';
import styled from 'styled-components/macro';

import TitleWithIcon from '../../components/TitleWithIcon';
import PriceCard from '../shared/PriceCard';

import { ArrowRightAlt } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import colors from '../../assets/colors';
import { pricingCards } from './const/BusinessOurPricingData';

const Container = styled.div`
  max-width: 948px;
  margin: 0 auto 20px auto;
`;
const StyledButton = styled(Button)`
  margin: auto;
  width: 100%;
  &.button {
    color: ${colors.primary};
  }
`;
const ArrowRight = styled(ArrowRightAlt)`
  color: ${colors.primary} !important;
  margin-left: 1rem;
  font-size: 30px !important;
`;

const BusinessPlans = () => {
  return (
    <Container>
      <TitleWithIcon
        title={
          <div>
            {' '}
            Grow your business with <br />
            the right plan{' '}
          </div>
        }
        description="Choose one of the prepaid credits plans depending on your business needs."
      />
      <PriceCard data={pricingCards} />
      <br />
      <StyledButton
        className="button"
        href={
          'https://gogetmy.zendesk.com/hc/en-us/sections/202713928-How-It-Works'
        }
      >
        TERMS & CONDITIONS
        <ArrowRight />
      </StyledButton>
    </Container>
  );
};

export default BusinessPlans;
