import React from 'react';
import wallet from '../../HomePricingPage/const/assets/wallet.png';

export const FaqData = [
  {
    question: 'How do I pay for my jobs?',
    content: (
      <>
        <br />
        <div>i) Purchase any GoGet Business credit package.</div>
        <div>ii) Request for a GoGetter on the app. </div>
        <div>
          iii) The credits will be deducted from your account when the job is
          completed.
        </div>
      </>
    )
  },
  {
    question: 'How do I buy credits?',
    content: (
      <>
        <br />
        <div>i) Select any of the pricing packages on this page..</div>
        <div> ii) Click on “Buy now” and select your payment method.</div>
        <div>iii) Start posting jobs and use your credits to pay.</div>
        <div></div>
        <div style={{ marginBottom: '2rem' }}></div>
        <div style={{ fontWeight: 'bold' }}>Types of payment </div>
        <div>a) Online bank transfer</div>
        <div>b) Paypal</div>
      </>
    )
  },
  {
    question: 'What package should I purchase for the first time?',
    content: (
      <>
        <div>
          {' '}
          The RM1,150 package has the best value because it comes with RM20 free
          credits, valid only on your first purchase.{' '}
        </div>
      </>
    )
  },
  {
    question: 'Will my credits expire?',
    content: (
      <>
        <p style={{ marginBottom: '1.5rem' }}>
          To ensure your credits are utilised to it’s fullest, simply complete
          one the criterias below and extend your credit expiration date.
        </p>
        <p style={{ marginBottom: '1.5rem' }}>
          i) 12 months from the last date you purchased a credit package or last
          date you received a compensation via credit top up.
        </p>
        <p style={{ marginBottom: '1.5rem' }}>
          ii) 12 months from your last completed job
        </p>
        <p style={{ fontStyle: 'italic', marginBottom: '4rem' }}>
          (whichever of the above two has a longer expiration date)
        </p>
        <p style={{ fontWeight: 'bold' }}>
          Where can I check my credits balance and expiration date?
        </p>
        <p style={{ marginBottom: '2rem' }}>
          Check your credit balance from the GoGet Dashboard.
        </p>
        <img alt="icon" width={437} height={246} src={wallet} />
        <p style={{ fontStyle: 'italic', textAlign: 'center' }}>
          Image shown here is for illustration purposes only
        </p>
      </>
    )
  },
  {
    question: 'Can I use credits to pay for my cost of goods?',
    content: (
      <>
        <br />
        Yes, you can! Just click on Shopping or Food and create a job. Your
        credits can be used to pay the GoGetter for your cost of items and
        delivery fee.
      </>
    )
  },
  {
    question: 'What is the minimum amount of credits needed?',
    content: (
      <>
        <br />
        The smallest credit plan is affordable for SMEs of any size, including
        solopreneur. Please refer to the credit plans above to choose and click
        from. <br />
        <br />
        Once you have credits on your account, the GoGet job fee will be
        deducted from your account.
      </>
    )
  }
];
