import React from 'react';
import styled from 'styled-components/macro';

import { data } from './const/BusinessOurPricingData';
import OurPricing from '../shared/OurPricing/index';

const Container = styled.div`
  width: 90%;
  max-width: 821px;
  margin: 0 auto;
  top: -72px;
  position: relative;
`;

function HomeOurPricing(props) {
  const { onClick } = props;
  return (
    <Container>
      <OurPricing onClick={onClick} data={data} />
    </Container>
  );
}

export default HomeOurPricing;
