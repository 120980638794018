import React, { useState } from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';

import { data } from './const/BusinessTypeOfJobData';
import TypeOfJob from '../shared/TypeOfJob/index';
import TitleWithIcon from '../../components/TitleWithIcon';

import Typeofjob from '../../assets/img/Typeofjob.png';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';
import RecruitPricing from './RecruitPricing';

const Container = styled.div`
  max-width: 948px;
  margin: 0 auto;
`;
const ToggleButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: 750px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;
const ToggleButton = styled(Button)`
  && {
    width: 100%;
    max-width: 218px;
    padding: 1rem 0.5rem;
    color: ${({ active }) => (active ? colors.primary : colors.greyDark)};
    margin-right: ${({ marginRight }) => marginRight && '0.5rem'};
    margin-left: ${({ marginLeft }) => marginLeft && '0.5rem'};
    box-shadow: 0px 2px 4px 0px #00000040;
    background-color: ${colors.white};
    text-transform: none;
    font-weight: 600;
    font-size: ${fontSize.xLarge};
    border: ${({ active }) =>
      active ? `2px solid ${colors.primary}` : `2px solid ${colors.white}`};
    border-radius: 10px;

    :hover {
      background-color: ${colors.white};
    }

    :focus {
      background-color: ${colors.white};
    }

    @media (max-width: 750px) {
      font-size: ${fontSize.large};
    }
  }
`;

const BusinessPricingTypeofJob = () => {
  const [isShowingHourly, setIsShowingHourly] = useState(true);

  return (
    <Container>
      <TitleWithIcon
        title="Type of job"
        icon={Typeofjob}
        description={
          <div>
            Hire by the hour with a minimum fee per hour, depending on the
            service type you need. Alternatively, hire longer term with GoGet
            Recruit job listing.
          </div>
        }
        alignCenter
      />
      <ToggleButtons>
        <ToggleButton
          variant="outlined"
          onClick={() => setIsShowingHourly(true)}
          active={isShowingHourly}
          marginRight
        >
          Hire by the hour
        </ToggleButton>
        <ToggleButton
          variant="outlined"
          onClick={() => setIsShowingHourly(false)}
          active={!isShowingHourly}
          marginLeft
        >
          GoGet Recruit
        </ToggleButton>
      </ToggleButtons>
      {isShowingHourly ? <TypeOfJob data={data} /> : <RecruitPricing />}
    </Container>
  );
};

export default BusinessPricingTypeofJob;
