import React from 'react';
import styled, { css } from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const gogetRecruit = './const/assets/gogetRecruit.png';

const Container = styled.div`
  display: flex;
  align-items center;
  justify-content: center;
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;
const ImageWrapper = styled.div`
  width: 490px;
  height: 300px;
  @media (max-width: 1010px) {
    width: 324px;
    height: unset;
    z-index: 1;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SharedText = css`
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSize.large};
  line-height: 19px;
`;
const Title = styled.p`
  ${SharedText};
  font-weight: bold;
  margin-top: 0;
`;

const Paper = styled.div`
  max-width: 458px;
  padding: 26px 28px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  left: -22px;
  @media (max-width: 1010px) {
    left: unset;
    justify-content: space-between;
    min-width: 292px;
    position: relative;
    top: -42px;
    padding-top: 4rem;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 0;
  }
`;
const PriceContainer = styled.p`
  font-size: ${fontSize.oneHalfRem};
  font-weight: 200;
`;
const DiscountChip = styled.div`
  background-color: ${colors.secondaryLight};
  padding: 0 1rem;
  border-radius: 20px;
  margin-top: 1rem;
`;
const DiscountText = styled.p`
  font-size: ${fontSize.medium};
  color: ${colors.secondary};
`;

const RecruitPricing = () => {
  return (
    <Container>
      <ImageWrapper>
        <StaticImage
          src={gogetRecruit}
          placeholder="blurred"
          alt="GoGet Recruit"
        />
      </ImageWrapper>
      <Paper>
        <Wrapper>
          <Title>Price Per Job Listing</Title>
          <PriceContainer>RM149 for the first time</PriceContainer>
          <DiscountChip>
            <DiscountText>N.P. RM199</DiscountText>
          </DiscountChip>
        </Wrapper>
      </Paper>
    </Container>
  );
};

export default RecruitPricing;
