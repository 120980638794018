import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Outskirt from '../../../assets/img/Outskirt.png';
import Central from '../../../assets/img/Central.png';

const CentralKV = './assets/Central(Default-KV).jpg';
const OutskirtKV = './assets/Outskirt(Default-KV).png';

export const data = [
  {
    type: 'Central',
    img: Central,
    pricing_categories: {
      imageMap: (
        <StaticImage
          src={CentralKV}
          style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
          placeholder="blurred"
          alt="Central Klang Valley"
        />
      ),
      content: [
        {
          dropoffs: 'More than 150',
          bike: 'RM7',
          car: 'RM9'
        },
        {
          dropoffs: '81 to 150',
          bike: 'RM7',
          car: 'RM10'
        },
        {
          dropoffs: '61 to 80',
          bike: 'RM8',
          car: 'RM11'
        },
        {
          dropoffs: '41 to 60',
          bike: 'RM9',
          car: 'RM11'
        },
        {
          dropoffs: '31 to 40',
          bike: 'RM10',
          car: 'RM12'
        },
        {
          dropoffs: '21 to 30',
          bike: 'RM11',
          car: 'RM13'
        },
        {
          dropoffs: '11 to 20',
          bike: 'RM12',
          car: 'RM14'
        },
        {
          dropoffs: '5 to 10',
          bike: 'RM15',
          car: 'RM18'
        }
      ]
    }
  },
  {
    type: 'Outskirt',
    img: Outskirt,
    pricing_categories: {
      imageMap: (
        <StaticImage
          src={OutskirtKV}
          style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
          placeholder="blurred"
          alt="Outskirt Klang Valley"
        />
      ),
      content: [
        {
          dropoffs: 'More than 150',
          bike: 'RM9',
          car: 'RM11'
        },
        {
          dropoffs: '81 to 150',
          bike: 'RM9',
          car: 'RM12'
        },
        {
          dropoffs: '61 to 80',
          bike: 'RM10',
          car: 'RM13'
        },
        {
          dropoffs: '41 to 60',
          bike: 'RM11',
          car: 'RM13'
        },
        {
          dropoffs: '31 to 40',
          bike: 'RM12',
          car: 'RM14'
        },
        {
          dropoffs: '21 to 30',
          bike: 'RM13',
          car: 'RM15'
        },
        {
          dropoffs: '11 to 20',
          bike: 'RM14',
          car: 'RM16'
        },
        {
          dropoffs: '5 to 10',
          bike: 'RM16',
          car: 'RM20'
        }
      ]
    }
  }
];
