import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';
// components
import Layout from '../../components/Layout';
import BusinessOurPricing from '../../views/BusinessPricingPage/BusinessOurPricing';
import BusinessPricingTypeofJob from '../../views/BusinessPricingPage/BusinessPricingTypeofJob';
import BusinessPricingDistance from '../../views/BusinessPricingPage/BusinessPricingDistance';
import DistanceFlatRate from '../../views/BusinessPricingPage/DistanceFlatRate';

import BusinessPlans from '../../views/BusinessPricingPage/BusinessPlans';
import TopHeader from '../../views/shared/TopHeaderV2';
import FAQSection from '../../views/shared/FAQ';
import ContactSection from '../../components/ContactSection';
import HeaderSeo from '../../components/HeaderSeo';
// data
import { FaqData } from '../../views/BusinessPricingPage/const/BusinessPricingFAQdata';
//images
import DesktopbackgroundOne from '../../assets/img/businessPricing/desktop-background-one.png';
import DesktopFAQForm from '../../assets/img/businessPricing/DesktopFAQForm.png';
import MobileFAQForm from '../../assets/img/businessPricing/MobileFAQForm.png';
import MobilebackgroundOne from '../../assets/img/businessPricing/mobile-background-one.png';

import MetaImg from '../../assets/img/meta/business_pricing.png';

import colors from '../../assets/colors';

import { SharedBackground } from '../../assets/sharedStyle';
import { graphql } from 'gatsby';

const DesktopBusinessPricingBanner =
  '../../assets/img/businessPricing/Banner-Desktop.jpg';
const MobileBusinessPricingBanner =
  '../../assets/img/businessPricing/Banner-Mobile.jpg';

const WrapperTop = styled.div`
  background-image: url(${DesktopbackgroundOne});
  ${SharedBackground};
  padding-bottom: 8rem;
  @media (max-width: 550px) {
    background-image: url(${MobilebackgroundOne});
  }
`;

const WrapperBottom = styled.div`
  background-image: url(${DesktopFAQForm});
  ${SharedBackground};
  padding-bottom: 105px;
  @media (max-width: 550px) {
    background-image: url(${MobileFAQForm});
  }
`;
const Teal = styled.span`
  font-weight: bold;
  color: ${colors.primary};
`;
const ContactDiv = styled.div`
  margin-top: 140px;
  @media (max-width: 550px) {
    margin-top: 0px;
  }
`;

const BusinessPricing = props => {
  const {
    data: { datoCmsBusinessPricing }
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const homeConstant = {
    title: (
      <div>
        {' '}
        Customise to fit <br />
        your budget{' '}
      </div>
    ),
    subTitle: (
      <div>
        Reduce overhead costs, skip the HR expenses and scale your business in
        the <Teal>most cost-efficient way!</Teal> We handle the verification,
        technology cost and welfare of your customers.
      </div>
    )
  };
  const refTypeOfJob = useRef(null);
  const refDistance = useRef(null);
  const refFlatRates = useRef(null);

  const handleClick = clickType => {
    const behaviour = {
      behavior: 'smooth',
      block: 'start'
    };
    if (clickType === 'typeOfJob') {
      if (refTypeOfJob.current) {
        refTypeOfJob.current.scrollIntoView(behaviour);
      }
    } else if (clickType === 'distance') {
      if (refDistance.current) {
        refDistance.current.scrollIntoView(behaviour);
      }
    } else if (clickType === 'flatRates') {
      if (refFlatRates.current) {
        refFlatRates.current.scrollIntoView(behaviour);
      }
    }
  };
  return (
    <>
      <HeaderSeo
        title="GoGet | Simple Pricing for On-Demand Part Time Workers"
        description="Reduce overhead cost. Pay only when you need, based on job types. Scale your business the cost-efficient way with our business credit plans."
        image={MetaImg}
        keywords="goget rates, goget pricing, goget business rates"
        pathname={props.location.pathname}
        schemaMarkup={datoCmsBusinessPricing.schemaMarkup}
      />
      <Layout hideDownloadGoGetter>
        <TopHeader
          backgroundImg={
            <StaticImage
              src={DesktopBusinessPricingBanner}
              loading="eager"
              style={{ height: '100%' }}
              objectPosition="left top"
              placeholder="blurred"
              alt="Business Pricing"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={MobileBusinessPricingBanner}
              loading="eager"
              style={{ height: '100%' }}
              imgStyle={{ height: '100%' }}
              objectFit="cover"
              quality={100}
              placeholder="blurred"
              alt="Business Pricing"
            />
          }
          title={homeConstant.title}
          description={homeConstant.subTitle}
          h4="Business Pricing"
          backgroundMobileHeight="810px"
        />
        <WrapperTop>
          <div style={{ background: '#F1F8F7' }}>
            <BusinessOurPricing onClick={handleClick} />
            <div ref={refTypeOfJob} style={{ scrollMargin: '60px' }}>
              <BusinessPricingTypeofJob />
            </div>
          </div>
          <div
            ref={refDistance}
            style={{ marginTop: '3rem', scrollMargin: '60px' }}
          >
            <BusinessPricingDistance />
          </div>
          <br />
          <br />
          <div
            ref={refFlatRates}
            style={{ marginTop: '3rem', scrollMargin: '60px' }}
          >
            <DistanceFlatRate />
          </div>
        </WrapperTop>
        <BusinessPlans />
        <WrapperBottom>
          <FAQSection data={FaqData} />
          <ContactDiv>
            <ContactSection
              hideBackground
              title={<div> Let us help grow your business! </div>}
              subtext="Provide us with your details and we will get in touch."
            />
          </ContactDiv>
        </WrapperBottom>
      </Layout>
    </>
  );
};

export default BusinessPricing;

export const query = graphql`
  query BusinessPricingQuery {
    datoCmsBusinessPricing {
      schemaMarkup
    }
  }
`;
