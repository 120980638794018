import React from 'react';
import ActivityCard from '../ActivityCard';

import {
  MainDiv,
  ColoredContainer,
  Content,
  Heading,
  SubText,
  SmallSubText,
  GreyText,
  BuyNowBtn,
  RecommendedDiv,
  RecommendIcon,
  AdvantageDiv
} from './styles';

const PriceCard = ({ data }) => {
  return (
    <div>
      <MainDiv>
        {data.map((plan, index) => (
          <React.Fragment key={index}>
            <ActivityCard
              customStyle={{
                marginTop: plan.recommended ? '5px' : '20px',
                width: '270px',
                height: '',
                backgroundColor: plan.recommended && '#6F54CD',
                color: plan.recommended && 'white'
              }}
            >
              {plan.recommended && <RecommendedDiv>RECOMMENDED</RecommendedDiv>}
              <ColoredContainer recommended={plan.recommended}>
                <Content>
                  <div>
                    {plan.icon && plan.recommended && (
                      <RecommendIcon src={plan.icon} />
                    )}
                    <Heading> {plan.header} </Heading>
                  </div>
                  <SubText> {plan.type} </SubText>
                  <SmallSubText> {plan.description} </SmallSubText>
                </Content>
              </ColoredContainer>
              <Content style={{ padding: '15px' }}>
                {plan.details.map((obj, index) => (
                  <div key={index}>
                    <SubText> {obj[0]} </SubText>
                    {obj[1] && (
                      <GreyText recommended={plan.recommended}>
                        {' '}
                        {obj[1]}{' '}
                      </GreyText>
                    )}
                    <br />
                  </div>
                ))}
                <BuyNowBtn
                  primary
                  background={'primary'}
                  onClick={() => {
                    window.analytics.track('sign_up_log_in', {
                      lavel: plan.publicId,
                      platform: 'static'
                    });
                    window.open(
                      `${process.env.GATSBY_PWA_PATH}/credits?public_id=${plan.publicId}`,
                      '_self'
                    );
                  }}
                >
                  Sign up and buy now
                </BuyNowBtn>
              </Content>
            </ActivityCard>
            <br />
          </React.Fragment>
        ))}
      </MainDiv>
      <MainDiv style={{ marginTop: '70px', marginBottom: '20px' }}>
        {data.map((plan, index) => (
          <ActivityCard
            key={index}
            customStyle={{
              width: '270px',
              height: '',
              border: 'none',
              boxShadow: 'none',
              background: 'none'
            }}
          >
            <AdvantageDiv>
              {plan.advantage.icon}
              <br />
              <div style={{ marginTop: '-15px' }}> {plan.advantage.text} </div>
            </AdvantageDiv>
          </ActivityCard>
        ))}
      </MainDiv>
      <br />
    </div>
  );
};

export default PriceCard;
