import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import fontSize from '../../../assets/fontSize';
import colors from '../../../assets/colors';
import PromotionIcon from '../../../assets/img/promotion.png';

const OutskirtJB = '../../BusinessPricingPage/const/assets/Outskirt(JB).jpg';
const OutskirtPG = '../../BusinessPricingPage/const/assets/Outskirt(PG).jpg';
const OutskirtN9 = '../../BusinessPricingPage/const/assets/Outskirt(N9).jpg';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 35%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: 925px) {
    width: 45%;
  }
  @media (max-width: 760px) {
    width: 85%;
  }
`;

const Button = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 128px;
  max-width: 128px;
  height: 55px;
  max-height: 55px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  padding: 0px 10px;
  font-size: ${fontSize.xLarge};
  border: ${props => (props.active ? '2px solid #00B4AD' : '')};
  &.small {
    width: 45px;
    height: 40px;
    font-weight: bold;
    margin-bottom: 1rem;
    ${({ active }) =>
      active &&
      css`
        color: ${colors.primary};
      `}
    @media (max-width: 760px) {
      width: 38px;
      height: 34px;
    }
  }
  img {
    width: 30%;
    margin-top: -4px;
  }

  @media (max-width: 400px) {
    margin: auto;
    margin-bottom: 10px !important;
    width: 100px;
    max-width: 100px;
  }
`;

const Card = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
`;
const ContainerWrapper = styled.div`
  display: flex;
  @media (max-width: 760px) {
    &.flex {
      flex-direction: column;
      padding-bottom: 16px;
    }
  }
`;
const ItemLeft = styled.div`
  width: 46%;
  max-height: 420px;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
  }
`;
const ItemRight = styled.div`
  flex: 1;
  padding-top: 1rem;
  @media (max-width: 760px) {
    padding-top: 0;
  }
`;
const Image = styled.div`
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  @media (max-width: 760px) {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 25px;
  &.center {
    align-items: center;
    padding-left: 0;
  }
`;
const SharedText = css`
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSize.large};
  line-height: 19px;
`;
const Title = styled.p`
  ${SharedText};
  font-weight: bold;
  margin-top: 0;
  @media (max-width: 500px) {
    height: 32px;
  }
`;
const Item = styled.p`
  ${SharedText};
  margin: 6px 0;
`;
const SharedFlex = css`
  display: flex;
  align-items: center;
`;
const FlexDiv = styled.div`
  ${SharedFlex};
`;
const ItemContiner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &.center {
    align-items: center;
  }
`;
const Discount = styled.div`
  background: #f1eaff;
  border-radius: 40px;
  display: flex;
  width: fit-content;
  padding: 3px 19px 3px 10px;
  align-items: center;
  margin: auto;
  margin-right: 19px;
  position: relative;
  top: -37px;
  @media (max-width: 760px) {
    position: unset;
    margin: 1rem auto 2rem auto;
  }
`;
const Icon = styled.img`
  width: 2rem;
  height: 2rem;
`;
const TextDiscount = styled.p`
  ${SharedText};
  margin: 0;
  color: #5e35b1;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;
const H4 = styled.h4`
  margin-top: 0;
  margin-bottom: 16;
  font-weight: bold;
  text-align: center;
`;

const FlexWrapper = styled.div`
  ${SharedFlex};
  margin-left: 25px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 760px) {
    display: none;
  }
`;
const CentralOutskirtWrapper = styled.div`
  ${SharedFlex};
  margin-left: ${props => props.marginNeeded && '1rem'};
`;
const ColoredCircle = styled.div`
  padding: 7px;
  border-radius: 50%;
  background: ${props => (props.central ? '#68B3AA' : '#87BEF9')};
  margin-right: 4px;
`;
const Text = styled.span`
  font-size: ${fontSize.medium};
  font-family: 'Open Sans', sans-serif;
`;
const MobileDiveWrapper = styled.div`
  display: none;
  @media (max-width: 760px) {
    display: block;
    ${SharedFlex};
    justify-content: center;
    flex: 1;
    margin-top: 8px;
  }
`;

const RenderCircleText = ({ text }) => (
  <CentralOutskirtWrapper marginNeeded={text !== 'Central'}>
    <ColoredCircle central={text === 'Central'} />
    <Text>{text}</Text>
  </CentralOutskirtWrapper>
);

const FlatRates = ({ data }) => {
  const [pricingType, setPricingType] = useState(0);
  const [pricingTypeImage, setPricingTypeImage] = useState('KV');

  const selected = data[pricingType].pricing_categories;

  const renderImage = () => {
    if (pricingTypeImage === 'KV') {
      return selected.imageMap;
    } else if (pricingTypeImage === 'PG') {
      return (
        <StaticImage
          src={OutskirtPG}
          style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
          objectPosition="center left"
          placeholder="blurred"
          alt="Outskirt Penang"
        />
      );
    } else if (pricingTypeImage === 'JB') {
      return (
        <StaticImage
          src={OutskirtJB}
          style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
          objectPosition="center left"
          placeholder="blurred"
          alt="Outskirt Klang Valley"
        />
      );
    } else if (pricingTypeImage === 'N9') {
      return (
        <StaticImage
          src={OutskirtN9}
          style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
          objectPosition="center left"
          placeholder="blurred"
          quality={100}
          alt="Outskirt Negeri Sembilan"
        />
      );
    }
  };

  const renderCentralOutskirt = () => (
    <React.Fragment>
      <RenderCircleText text="Central" />
      <RenderCircleText text="Outskirts" />
    </React.Fragment>
  );

  const createButtons = () => {
    return (
      <ButtonGroup>
        {data.map((obj, i) => (
          <Button
            key={i}
            active={i === pricingType}
            onClick={() => {
              setPricingType(i);
              setPricingTypeImage('KV');
            }}
          >
            <img alt="object_image_icon" src={obj.img} />
            {obj.type}
          </Button>
        ))}
      </ButtonGroup>
    );
  };

  const createContent = () => {
    return (
      <ContainerWrapper className="flex">
        <ItemLeft>
          <Image>{renderImage()}</Image>
          <ButtonWrapper isThree={pricingType === 1}>
            <Button
              onClick={() => setPricingTypeImage('KV')}
              active={pricingTypeImage === 'KV'}
              className="small"
            >
              KV
            </Button>
            {pricingType === 1 && (
              <>
                <Button
                  onClick={() => setPricingTypeImage('N9')}
                  active={pricingTypeImage === 'N9'}
                  className="small"
                >
                  N9
                </Button>
                <Button
                  onClick={() => setPricingTypeImage('PG')}
                  active={pricingTypeImage === 'PG'}
                  className="small"
                >
                  PG
                </Button>
                <Button
                  onClick={() => setPricingTypeImage('JB')}
                  active={pricingTypeImage === 'JB'}
                  className="small"
                >
                  JB
                </Button>
              </>
            )}
          </ButtonWrapper>
        </ItemLeft>
        <MobileDiveWrapper>{renderCentralOutskirt()}</MobileDiveWrapper>
        <ItemRight>
          <Discount>
            <Icon src={PromotionIcon} alt="alt" />
            <TextDiscount>Enjoy 15% off your first order</TextDiscount>
          </Discount>
          {
            <ContainerWrapper>
              <Wrapper>
                <Title>Number of dropoffs</Title>
                <ItemContiner>
                  {selected &&
                    selected.content.map((obj, i) => (
                      <FlexDiv key={i}>
                        <Item>{obj.dropoffs}</Item>
                      </FlexDiv>
                    ))}
                </ItemContiner>
              </Wrapper>
              <Wrapper className="center">
                <Title>Bike</Title>
                <ItemContiner className="center">
                  {selected &&
                    selected.content.map((obj, i) => (
                      <FlexDiv key={i}>
                        <Item>{obj.bike}</Item>
                      </FlexDiv>
                    ))}
                </ItemContiner>
              </Wrapper>
              <Wrapper className="center">
                <Title>Car</Title>
                <ItemContiner className="center">
                  {selected &&
                    selected.content.map((obj, i) => (
                      <FlexDiv key={i}>
                        <Item>{obj.car}</Item>
                      </FlexDiv>
                    ))}
                </ItemContiner>
              </Wrapper>
            </ContainerWrapper>
          }
          <FlexWrapper>{renderCentralOutskirt()}</FlexWrapper>
        </ItemRight>
      </ContainerWrapper>
    );
  };

  return (
    <div>
      <H4>Where is your pick up location?</H4>
      {createButtons()}
      <br />
      <br />
      <Card>{createContent()}</Card>
    </div>
  );
};

export default FlatRates;
