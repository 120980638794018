import styled from 'styled-components/macro';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

import Button from '../../../components/Button';

export const MainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 880px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ColoredContainer = styled.div`
  width: 100%;
  height: 159px;
  background: #f1f8f7;
  align-items: center;
  display: flex;
  border-radius: 20px 20px 0px 0px;
  padding-top: ${props => props.recommended && '15px'};
  background-color: ${props => props.recommended && colors.secondary};
`;
export const Content = styled.div`
  margin: auto;
  width: 100%;
  padding: 10px;
  text-align: center;
`;
export const Heading = styled.h1`
  font-size: ${fontSize.twoRem};
  margin: 0 0 5px 0px;
`;
export const SubText = styled.div`
  font-size: ${fontSize.xLarge};
  margin: 0 0 5px 0px;
  font-weight: 600;
  line-height: 22px;
  .teal {
    color: ${colors.primary};
  }
`;
export const SmallSubText = styled.div`
  font-size: ${fontSize.large};
  margin: 0 0 5px 0px;
  font-weight: 300;
`;
export const GreyText = styled.div`
  font-size: ${fontSize.medium};
  color: ${props => (props.recommended ? 'white' : '#898989')};
  line-height: 16px;
  text-align: center;
  margin: -5px;
  font-weight: 300;
`;
export const BuyNowBtn = styled(Button)`
  height: 52px !important;
  margin: 20px 0px 15px 0px !important;
`;
export const RecommendedDiv = styled.div`
  background: #ffa000;
  width: 100%;
  position: absolute;
  border-radius: 20px 20px 0px 0px;
  max-width: 270px;
  margin-top: -1px;
  text-align: center;
  padding: 7px 0px;
  font-weight: bold;
  font-size: ${fontSize.medium};
`;
export const AdvantageDiv = styled.div`
  text-align: center;
  font-size: ${fontSize.xLarge};
`;
export const RecommendIcon = styled.img`
  width: 44px;
  position: absolute;
  margin-left: -96px;
  margin-top: -4px;
`;
